import React from "react";
import Extra1 from "../../assets/presentes/linhapresente11.jpg";
import Branco from "../../assets/branco.png";

import { Fade, Reveal } from "react-reveal";

const Products = () => {
  const products = PRODUCTS.products;
  const products1 = PRODUCTS1.products1;
  const products2 = PRODUCTS2.products2;
  const products3 = PRODUCTS3.products3;
  return (
    <>
      <div
        className="
    grid grid-cols-1 gap-x-36 gap-y-14 mx-10 items-center 
    justify-center 
    sm:grid-cols-1 sm:mx-10 
    md:mx-10 md:grid-cols-2
    lg:mx-28 lg:grid-cols-3 lg:gap-x-10 
          "
      >
        {products2.map((item, index) => (
          <Reveal key={index}>
            <Fade bottom big>
              <div className="h-full"></div>
            </Fade>
          </Reveal>
        ))}
        {products.map((item, index) => (
          <Reveal key={index}>
            <Fade bottom big>
              <div className="h-full">
                <div className="bg-white w-full h-full drop-shadow-2xl rounded-md transition ease-in-out hover:-translate-y-1 hover:scale-110">
                  <div className="w-full p-7 items-center flex flex-col">
                    <img
                      src={item.image_url}
                      alt=""
                      className="w-full h-48 object-contain"
                    />
                    <div className="w-full mt-10 flex flex-col items-center h-full">
                      <span className="font-poppins font-bold text-preto text-2xl p-1 -mb-2"></span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        {item.name}
                      </span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        <b>Cod .EAN: </b>
                        {item.COD}
                      </span>

                      <span className="font-poppins font-light text-base text-preto">
                        {item.description || "\u00A0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Reveal>
        ))}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div
        className="
    grid grid-cols-1 gap-x-36 gap-y-14 mx-10 items-center 
    justify-center 
    sm:grid-cols-1 sm:mx-10 
    md:mx-10 md:grid-cols-2
    lg:mx-28 lg:grid-cols-3 lg:gap-x-10 
          "
      >
        {products3.map((item, index) => (
          <Reveal key={index}>
            <Fade bottom big>
              <div className="h-full">
                <div className="bg-white w-full h-full drop-shadow-2xl rounded-md transition ease-in-out hover:-translate-y-1 hover:scale-110">
                  <div className="w-full p-7 items-center flex flex-col">
                    <div className="w-full mt-10 flex flex-col items-center h-full">
                      <span className="font-poppins font-bold text-preto text-2xl p-1 -mb-2"></span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        {item.name1}
                      </span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        <b>Capacidade </b>
                        {item.capacidade1}
                      </span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10"></span>
                      <br></br>
                      <span className="font-poppins font-light text-base text-preto">
                        {item.description || "\u00A0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Reveal>
        ))}
        {products1.map((item, index) => (
          <Reveal key={index}>
            <Fade bottom big>
              <div className="h-full">
                <div className="bg-white w-full h-full drop-shadow-2xl rounded-md transition ease-in-out hover:-translate-y-1 hover:scale-110">
                  <div className="w-full p-7 items-center flex flex-col">
                    <div className="w-full mt-10 flex flex-col items-center h-full">
                      <span className="font-poppins font-bold text-preto text-2xl p-1 -mb-2"></span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        {item.name1}
                      </span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        <b>Número </b>
                        {item.número1}
                      </span>
                      <span className="font-poppins font-semibold text-preto text-2xl p-1 max-w-max before:block before:absolute before:transform before:translate-y-3 before:inset-0 before:inset-y-2 before:bg-[#FFE719] relative inline-block before:-z-10">
                        <b>Capacidade </b>
                        {item.capacidade1}
                      </span>
                      <span className="font-poppins font-light text-base text-preto">
                        {item.description || "\u00A0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Reveal>
        ))}
      </div>
    </>
  );
};

export default Products;

const PRODUCTS = {
  products: [
    {
      name: "Conjunto Flávia 6 Pçs",
      Código: "355",
      COD: "7897449703554",
      image_url: Extra1,
    },
  ],
};

const PRODUCTS1 = {
  products1: [
    {
      name1: "Caçarola C/ Cabo",
      número1: "16",
      capacidade1: "1,45 L",
    },
    {
      name1: "Caçarola C/ Cabo",
      número1: "18",
      capacidade1: "2,0 L",
    },
    {
      name1: "Caçarola C/ Alça",
      número1: "18",
      capacidade1: "2,0 L",
    },
    {
      name1: "Canecão",
      número1: "14",
      capacidade1: "1,90 L",
    },
    {
      name1: "Frigideira C/ Tampa",
      número1: "20",
      capacidade1: "1,30 L",
    },
  ],
};

const PRODUCTS2 = {
  products2: [
    {
      name: "Jogo P/ Mantimento 5 Pçs",
      Código: "132",
      COD: "7897449701321",
      image_url: Extra1,
    },
  ],
};

const PRODUCTS3 = {
  products3: [
    {
      name1: "Panela de Pressão",
      número1: "-",
      capacidade1: "4,50 L",
    },
  ],
};
